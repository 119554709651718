




















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class ProjectBasic extends Vue {
    pid: any = "";
    project: object = {};
    newProject: any = {};

    vertical_list: object = ["c_introduction"];

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            let pid = res.data.project_id;
            this.pid = res.data.project_id;
            return pid;
        });
        return result;
    }

    getData(pid: any) {
        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/project/${pid}/show/0`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang")
            }
        }).then((res: any) => {
            this.project = res.data.data;
            this.setData(this.project);
            // console.log(res.data.data);
        });
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any) {
        switch (index) {
            case "id":
                return `${this.$t("project.project")} ID`;
                break;
            case "contact":
                return `${this.$t("sign.contactLabel")}`;
                break;
            case "email":
                return `${this.$t("sign.contact_email_label")}`;
                break;
            case "mobile":
                return `${this.$t("sign.contact_mobile_label")}`;
                break;
            case "company":
                return `${this.$t("sign.contact_company_label")}`;
                break;
            case "position":
                return `${this.$t("sign.contact_position_label")}`;
                break;
            case "c_introduction":
                return `${this.$t("sign.contact_compIntro_label")}`;
                break;
            case "website":
                return `${this.$t("sign.contact_compWebsite_label")}`;
                break;
            case "beizhu":
                return `${this.$t("sign.contact_beizhu_label")}`;
                break;
            default:
                return index;
                break;
        }
    }

    // 设置需要现实的userdata
    setData(oldData: any) {
        this.newProject = JSON.parse(JSON.stringify(oldData));

        delete this.newProject.user_id;
        delete this.newProject.updated_at;
        delete this.newProject.created_at;
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            if (res) {
                this.getData(res);
            }
        });
    }
}
