



















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Form, FormItem, Input, Radio, RadioGroup } from "element-ui";
import { AlDate } from "@/components/al/getDate";
@Component({
    components: {
        "el-form": Form,
        "el-form-item": FormItem,
        "el-input": Input,
        "el-radio-group": RadioGroup,
        "el-radio": Radio,
    },
})
export default class UserInfo extends Vue {
    @Prop({ type: Object, required: true, default: () => ({}) }) userdata!: any;

    showInfos: any[] = ["id", "name", "email", "created_at"]; //显示给用户的key
    dateTypeInfos: any[] = ["created_at"]; //需要转换时间格式的key

    private validatePhoneRule(rule: any, value: any, callback: any) {
        let phonereg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (value === "") {
            callback(new Error("电话号码不能为空"));
        } else if (!phonereg.test(value)) {
            callback(new Error("电话号码格式错误"));
        } else {
            callback();
        }
    }

    newUserData: any = {}; //存放显示给用户的信息
    changeData: any = {};
    showInput: String = "";
    modifying: Boolean = false;

    changeFormRules: Object = {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
    };

    loading: any;

    /**开启loading */
    startLoading() {
        this.loading = this.$loading({
            lock: true,
            text: " ",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
    }

    /**关闭loading */
    closeLoading() {
        this.loading.close();
        this.loading = "";
    }

    // 设置需要现实的userdata
    setData(oldData: any) {
        if (JSON.stringify(oldData) !== "{}") {
            const transDate = (date: any) => {
                const _date = new AlDate(date);

                const year = _date.year;
                const month = _date.getFullMonth();
                const day = _date.getFullDay();
                const hour = _date.getFullHours();
                const minute = _date.getFullMinutes();
                const second = _date.getFullSeconds();
                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            };

            const add_delay = (object: any, key: any, data: any, i: any) => {
                setTimeout(() => {
                    this.$set(object, key, data);
                }, 50 * i);
            };

            let i: number = 0;
            for (const key in oldData) {
                if (Object.prototype.hasOwnProperty.call(oldData, key)) {
                    let element = oldData[key];
                    if (
                        this.showInfos.findIndex((val: any) => val == key) != -1
                    ) {
                        i++;
                        if (
                            this.dateTypeInfos.findIndex(
                                (val: any) => val == key
                            ) != -1
                        )
                            element = transDate(element);

                        add_delay(this.newUserData, key, element, i);
                    }
                }
            }

            this.changeData = JSON.parse(JSON.stringify(this.newUserData));

            if (this.loading) this.closeLoading();
        }
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any) {
        switch (index) {
            case "id":
                return `${this.$t("scaffold.user")} ID`;
                break;
            case "name":
                return `${this.$t("sign.regist_uname_label")}`;
                break;
            case "email":
                return `${this.$t("sign.email")}`;
                break;
            case "created_at":
                return `${this.$t("scaffold.created_date")}`;
                break;
            default:
                return index;
                break;
        }
    }

    @Watch("userdata", { deep: true })
    userdataChange(newValue: any, oldValue: any) {
        // console.log(newValue);
        this.setData(newValue);
    }

    // 点击修改按钮
    handleModify(key: any, data: any) {
        this.changeData[key] = data;
        // console.log(this.changeData);
        // console.log({ [key]: data });
        this.showInput = key;
        this.modifying = true;
    }
    // 点击取消按钮
    cancelModify(key: any, data: any) {
        // this.changeData = "";
        this.showInput = "";
        this.modifying = false;
    }

    //提交修改
    subModify(key: any, data: any, formname: any) {
        this.validateForm(formname).then((res: any) => {
            console.log(res);
            if (res == true) {
                const subData = {
                    [key]: this.changeData[key],
                    api_token: sessionStorage.getItem("ms_token"),
                };
                const loading = this.$loading({
                    lock: true,
                    text: "拼命加载中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$axios({
                    url: `${this.$api.apiList.modifyUserInfo.url}`,
                    method: this.$api.apiList.modifyUserInfo.method,
                    data: subData,
                })
                    .then((res: any) => {
                        // console.log(res);
                        loading.close();
                        if (res.data.res == 1) {
                            this.$emit("update:userdata", res.data.user);
                            this.changeData[key] = "";
                            this.modifying = false;
                            this.showInput = "";
                            this.$notify({
                                title: "修改成功",
                                message: `${this.setKeyName(
                                    key
                                )}修改成功，当前为${res.data.user[key]}`,
                                type: "success",
                            });
                        } else {
                            alert("修改失败");
                        }
                    })
                    .catch((err: any) => {
                        // console.log(err.response);
                        loading.close();
                        const h = this.$createElement;
                        this.$notify.error({
                            title: `修改失败（${err.response.status} ${err.response.statusText}）`,
                            message: h(
                                "i",
                                { style: "color: teal" },
                                err.response.data.message
                            ),
                        });
                    });
            }
        });
    }

    //表单验证
    async validateForm(formName: string) {
        let result = await (this.$refs[formName] as any)[0]
            //@ts-ignore
            .validate()
            //@ts-ignore
            .catch((error) => error);
        return result;
    }

    created() {
        this.startLoading();
    }

    mounted() {
        this.setData(this.userdata);
    }
}
