










































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
@Component({})
export default class ProjectBasic extends Vue {
    @Action("setRegistCheckItemActions") setRegistCheckItem: any;
    @Getter("getRegistCheckItem") getRegistCheckItem: any;

    eid: any = "";
    project: object = {}; //存放原始数据
    newProject: any = {}; //存放展示在页面的数据

    showInfos: any[] = [
        "id",
        "name",
        "country_name",
        "avatar",
        "birthday",
        "email",
        "phone",
        "live_address",
        "wechat",
        "graduate",
        "degree",
        "major",
        "company",
        "position",
        "technical_field_name",
        "custom_field",
        "technical_position",
        "other_position",
        "type",
        "brief",
    ]; //存放展示给用的信息的key

    vertical_list: object = ["brief", "avatar"]; //标题换行显示的信息的key

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        }).then((res: any) => {
            let eid = res.data.expert_id;
            this.eid = res.data.expert_id;
            // console.log(res.data);

            return eid;
        });
        return result;
    }

    /**获取数据 */
    getData(eid: any) {
        // console.log(eid);

        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/expert/${eid}/show`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang"),
            },
        }).then((res: any) => {
            this.project = res.data.data;
            this.setData(this.project);
            // console.log(res.data.data);
        });
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any, item: any) {
        switch (index) {
            case "id":
                return `${this.$t("expert.expert")} ID`;
                break;
            case "name":
                return `${this.$t("sign.expert_name_label")}`;
                break;
            case "country_name":
                return `${this.$t("sign.expert_country_label")}`;
                break;
            case "avatar":
                return `${this.$t("sign.expert_avatar")}`;
                break;
            case "birthday":
                return `${this.$t("sign.expert_birthday_label")}`;
                break;
            case "email":
                return `${this.$t("sign.contact_email_label")}`;
                break;
            case "phone":
                return `${this.$t("sign.expert_phone_label")}`;
                break;
            case "wechat":
                return `${this.$t("sign.expert_wechat_label")}`;
                break;
            case "graduate":
                return `${this.$t("sign.expert_graduate_label")}`;
                break;
            case "degree":
                return `${this.$t("sign.expert_degree_label")}`;
                break;
            case "technical_field_name":
                return `${this.$t("sign.expert_technical_field_label")}`;
                break;
            case "company":
                return `${this.$t("sign.expert_company_label")}`;
                break;
            case "position":
                return `${this.$t("sign.expert_position_label")}`;
                break;
            case "brief":
                return `${this.$t("sign.expert_brief_label")}`;
                break;
            case "executive_position":
                return `${this.$t("sign.expert_executive_position_label")}`;
                break;
            case "professional_title":
                return `${this.$t("sign.expert_professional_title_label")}`;
                break;
            case "faculty_position":
                return `${this.$t("sign.expert_faculty_position_label")}`;
                break;
            case "professional_affiliation":
                return `${this.$t(
                    "sign.expert_professional_affiliation_label"
                )}`;
                break;
            case "honor":
                return `${this.$t("sign.expert_honor_label")}`;
                break;
            case "live_address":
                return `${this.$t("sign.expert_liveAddress_label")}`;
                break;
            case "technical_position":
                return `${this.$t("sign.expert_technicalPosition_label")}`;
                break;
            case "other_position":
                return `${this.$t("sign.expert_otherPosition_label")}`;
                break;
            case "custom_field":
                return `${this.$t("sign.expert_customField_label")}`;
                break;
            case "major":
                return `${this.$t("sign.expert_major_label")}`;
                break;
            case "type":
                return `${this.$t("sign.expert_type_label")}`;
                break;
            default:
                return index;
                break;
        }
    }

    //设置content
    setContent(index: any, item: any) {
        switch (index) {
            case "avatar":
                return item
                    ? `<img src="${item}" alt="item" style="max-width:300px;height:auto">`
                    : this.$t("scaffold.noData");
                break;
            case "birthday":
                if (item) {
                    const _date = new Date(item);
                    const year = _date.getUTCFullYear();
                    const month =
                        _date.getUTCMonth() + 1 < 10
                            ? `0${_date.getUTCMonth() + 1}`
                            : _date.getUTCMonth() + 1;
                    const day =
                        _date.getUTCDate() + 1 < 10
                            ? `0${_date.getUTCDate() + 1}`
                            : _date.getUTCDate() + 1;
                    return `${year}-${month}-${day}`;
                } else {
                    return this.$t("scaffold.noData");
                }
                break;
            case "graduate":
            case "degree":
                return item == "/" ? this.$t("scaffold.noData") : item;
                break;
            default:
                return item ? item : this.$t("scaffold.noData");
                break;
        }
    }

    // 设置需要显示的userdata
    async setData(oldData: any) {
        // delete this.project.country_id;
        // delete this.project.technical_field;
        // delete this.project.expert_cn;
        // delete this.project.expert_en;
        // delete this.project.social_title;
        // delete this.project.user_id;
        // delete this.project.updated_at;
        // delete this.project.created_at;
        // let test:any[]=[];
        // for (const key in this.project) {
        //     if (Object.prototype.hasOwnProperty.call(this.project, key)) {
        //         const element = (this.project as any)[key];
        //         test.push(key)
        //         console.log(JSON.stringify(test));

        //     }
        // }

        let project = JSON.parse(JSON.stringify(oldData));

        const social_title = JSON.parse(project.social_title);
        if (social_title) {
            const social_title_keys = Object.keys(social_title);
            for (const i of social_title_keys) {
                project[i] = social_title[i];
            }
        }
        let i: number = 0;
        const add_delay = (object: any, key: any, data: any, i: any) => {
            setTimeout(() => {
                this.$set(object, key, data);
            }, 50 * i);
        };

        for (const key in project) {
            if (Object.prototype.hasOwnProperty.call(project, key)) {
                const element = project[key];
                if (this.showInfos.findIndex((val: any) => val == key) != -1) {
                    /**如何key是'type',从store获取数据字典，依据key返回value */
                    let data;
                    if (key === "type") {
                        if (!this.getRegistCheckItem("expert_type"))
                            await this.setRegistCheckItem();
                        data = this.getRegistCheckItem(
                            "expert_type",
                            element.split(",")
                        );
                    } else data = element;
                    i++;
                    add_delay(this.newProject, key, data, i);
                }
            }
        }
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            this.getData(res);
        });
    }
}
